import React, { useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBaby, faLocationDot, faLocationPin } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-bootstrap";
import SwipeableRow from "./SwipeableRow"; // Import du composant
import { ReactComponent as Logo } from "../../assets/images/driver.svg";

import {
  onTheWay,
  arrivedToPick,
  startTheRide,
  stopTheRide,
} from "./transferFunctions";
import "./TransferRow.css";


function TransferRow({
  course,
  unassignDriver,
  handleCourseClick,
  setBgColor,
  handleOpenPaymentMethod, // Nouvelle fonction passée depuis le parent pour ouvrir la modal
}) {
  const [currentStatus, setCurrentStatus] = useState(course.status);
  const [isFetchingLocation, setIsFetchingLocation] = useState(false);

  const handleSwipeLeftAction = () => {
    if (
      course.driver_name &&
      window.confirm("Voulez-vous désassigner ce chauffeur?")
    ) {
      unassignDriver(course.IdResa, course.IdChauffeur);
    }
  };

  const swipeLeftButton = () => {
    if (course.driver_name) {
      return <Logo width="40" />;
    }
    return null;
  };

  const isSwipeAllowed = parseInt(localStorage.getItem("user_id")) === course.IdChauffeur;
  // Méthode centralisée pour gérer la géolocalisation avant d'exécuter l'action du statut
  const fetchLocationAndExecute = useCallback((action) => {
    setIsFetchingLocation(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const loc = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setIsFetchingLocation(false);
          action(loc); // Exécute l'action avec la localisation
        },
        (error) => {
          console.error("Erreur de géolocalisation :", error);
          alert("Erreur lors de la récupération de la localisation.");
          setIsFetchingLocation(false);
        }
      );
    } else {
      alert("La géolocalisation n'est pas prise en charge par ce navigateur.");
      setIsFetchingLocation(false);
    }
  }, []);
  // Méthode centralisée pour gérer le texte et l'action associée au statut
  // Méthode centralisée pour gérer l'action du statut
  const handleSwipeRightAction = (status) => {
    switch (status) {
      case "NOT_STARTED":
        return {
          action: () => fetchLocationAndExecute((loc) => onTheWay(course.IdResa, setCurrentStatus, loc)),
          text: "ER",
          color: "#3B71CA",
        };
      case "ON_THE_WAY":
        return {
          action: () => fetchLocationAndExecute((loc) => arrivedToPick(course.IdResa, setCurrentStatus, loc)),
          text: "SP",
          color: "#54B4D3",
        };
      case "ARRIVED":
        return {
          action: () => fetchLocationAndExecute((loc) => startTheRide(course.IdResa, setCurrentStatus, loc)),
          text: "PEC",
          color: "#14A44D",
        };
      case "PICKED_UP":
        return {
          action: () => fetchLocationAndExecute((loc) => stopTheRide(course.IdResa, course.Paiement, handleOpenPaymentMethod, setCurrentStatus, loc)),
          text: "DP",
          color: "#E4A11B",
        };
      default:
        return {
          action: null,
          text: "Finished",
          color: "",
        };
    }
  };

  const rightSwipeAction = handleSwipeRightAction(currentStatus);
  const calculateElapsedTime = (date) => {
    const now = new Date();
    const eventDate = new Date(date);

    const elapsedMilliseconds = now - eventDate;
    const elapsedMinutes = Math.floor(elapsedMilliseconds / (1000 * 60));
    const elapsedHours = Math.floor(elapsedMinutes / 60);
    const remainingMinutes = elapsedMinutes % 60;

    if (elapsedHours > 0) {
      return `${elapsedHours}h ${remainingMinutes}min`;
    } else {
      return `${remainingMinutes}min`;
    }
  };

  const formatTime = (date) => {
    const eventDate = new Date(date);
    const hours = eventDate.getHours().toString().padStart(2, '0'); // Ajoute un zéro devant si nécessaire
    const minutes = eventDate.getMinutes().toString().padStart(2, '0'); // Ajoute un zéro devant si nécessaire
    return `${hours}:${minutes}`;
  };

  const getElapsedTimeForStatus = (course, status) => {
    switch (status) {
      case "ON_THE_WAY":
        return ` ${formatTime(course.on_the_way_time)} (${calculateElapsedTime(course.on_the_way_time)})`;
      case "ARRIVED":
        return ` ${formatTime(course.arrived_to_pick)} (${calculateElapsedTime(course.arrived_to_pick)})`;
      case "PICKED_UP":
        return ` ${formatTime(course.picked_up_time)} (${calculateElapsedTime(course.picked_up_time)})`;
      case "DROPPED_OFF":
        return ` ${formatTime(course.dropped_off_time)} (${calculateElapsedTime(course.dropped_off_time)})`;
      default:
        return "";
    }
  };

  const status_libelle = (status) => {
    switch (status) {
      case "ON_THE_WAY":
        return `On the way${getElapsedTimeForStatus(course, status)}`;
      case "ARRIVED":
        return `Arrived${getElapsedTimeForStatus(course, status)}`;
      case "PICKED_UP":
        return `Picked Up${getElapsedTimeForStatus(course, status)}`;
      case "DROPPED_OFF":
        return `Dropped off${getElapsedTimeForStatus(course, status)}`;
      default:
        return ""; // Si aucun texte n'est requis
    }
  };

  return (
    <SwipeableRow
      BgColor={`${setBgColor(course.Prix, course.Etat, course.driver_name)}`}
      onSwipeLeftAction={handleSwipeLeftAction}
      swipeLeftButton={swipeLeftButton}
      userRole={localStorage.getItem("role")}
      onSwipeRightAction={rightSwipeAction.action} // Utilisation de la fonction d'action
      swipeRightButton={() => rightSwipeAction.text} // Texte correspondant au statut
      isSwipeEnabled={isSwipeAllowed}
      colorRightButton={() => rightSwipeAction.color} // Couleur correspondant au statut
      isFetchingLocation={isFetchingLocation}
    >
      <Row
        className={`${setBgColor(course.Prix, course.Etat, course.driver_name)} transfer-row`}
        onClick={handleCourseClick(course)}
        key={course.IdResa + "-" + course.IdChauffeur}
        data-idresa={course.IdResa}
      >
        <Col className="m-2">
          <strong style={{ fontSize: "14px" }}>{course.HeureResa}</strong>
          {(() => {
            const regex = /\b(baby|seat|booster|extra|siege|bébé|rehausseur|enfant|fils|fille|petit|child|children|kid|infant|toddler|niño|niños|bebé|hijo|hijos|pequeño|bambino|bambini|neonato|figlio|figli|piccolo|kind|kinder|sitz|bambi)\b/i;
            return regex.test(course.Commentaire) && (
              <span style={{ fontSize: "25px", position: "absolute", right: 10, top: 0, float: "right" }}>
                <FontAwesomeIcon icon={faBaby} />
              </span>
            );
          })()}
          <span style={{ paddingLeft: "5px" }}>
            / Chauffeur : <strong>{course.driver_name ? course.driver_name : "Aucun"}</strong>
            <span>{status_libelle(currentStatus) ? " / " + status_libelle(currentStatus) : ""}</span>
          </span>
          <div className="fw-light" style={{ paddingTop: "10px" }}>
            <FontAwesomeIcon icon={faLocationDot} /> Pick-up : <span className="fw-bold">{course.AdresseDep.toLowerCase().includes("cdg") ? "CDG" : course.AdresseDep}</span>
          </div>
          <div className="fw-light">
            <FontAwesomeIcon icon={faLocationPin} /> Drop-off : <span className="fw-bold">{course.AdresseArr.toLowerCase().includes("cdg") ? "CDG" : course.AdresseArr}</span>
          </div>
        </Col>

      </Row>
    </SwipeableRow >
  );
}

export default TransferRow;
