// transferFunctions.js
import api from "../../utils/api";
import React from "react";
import { Button, Row, Modal } from "react-bootstrap";

export const onTheWay = async (transferId, setStatus, location) => {
  try {
    const response = await api.get("/transfers/check-not-finished");
    if (response.data.nb !== 0) {
      alert("You have rides not finished. Please end it");
    } else {
      await api.post(`/transfers/${transferId}/on-the-way`, {
        location: {
          lat: location?.lat,
          lng: location?.lng,
        },
      });
      setStatus("ON_THE_WAY");
    }
  } catch (error) {
    console.error("Error updating status to 'ON_THE_WAY'", error);
  }
};

export const arrivedToPick = async (transferId, setStatus, location) => {
  try {
    await api.post(`/transfers/${transferId}/arrived-to-pick`, {
      location: {
        lat: location?.lat,
        lng: location?.lng,
      },
    });
    setStatus("ARRIVED");
  } catch (error) {
    console.error("Error updating status to 'ARRIVED'", error);
  }
};

export const startTheRide = async (transferId, setStatus, location) => {
  try {
    await api.post(`/transfers/${transferId}/start-the-ride`, {
      location: {
        lat: location?.lat,
        lng: location?.lng,
      },
    });
    setStatus("PICKED_UP");
  } catch (error) {
    console.error("Error updating status to 'PICKED_UP'", error);
  }
};

export const choosePaymentMethod = async (
  transferId,
  method,
  setShowPaymentMethod
) => {
  try {
    await api.post(`/transfers/${transferId}/payment-method`, {
      payment_method: method,
    });
    setShowPaymentMethod(false);
  } catch (error) {
    console.error("Error choosing payment method", error);
  }
};

export const stopTheRide = async (
  transferId,
  payment,
  setShowPaymentMethod,
  setStatus,
  location
) => {
  try {
    if (payment === "PAB") setShowPaymentMethod(true);
    await api.post(`/transfers/${transferId}/stop-the-ride`, {
      location: {
        lat: location?.lat,
        lng: location?.lng,
      },
    });
    setStatus("DROPPED_OFF");
  } catch (error) {
    console.error("Error stopping the ride", error);
  }
};

export const PaymentMethodModal = ({
  transferId,
  show,
  handleClose,
  choosePaymentMethod,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Choose payment method</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="p-2">
          <Button
            variant="warning"
            size="lg"
            onClick={() => choosePaymentMethod(transferId, "CB", handleClose)}
          >
            Credit card
          </Button>
        </Row>
        <Row className="p-2">
          <Button
            variant="warning"
            size="lg"
            onClick={() =>
              choosePaymentMethod(transferId, "Especes", handleClose)
            }
          >
            Cash
          </Button>
        </Row>
        <Row className="p-2">
          <Button
            variant="warning"
            size="lg"
            onClick={() => choosePaymentMethod(transferId, "ANCV", handleClose)}
          >
            ANCV
          </Button>
        </Row>
      </Modal.Body>
      {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
    </Modal>
  );
};
