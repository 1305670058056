import React from "react";

const ErrorPage = () => {
    const handleRetry = () => {
        window.location.reload(); // Recharge l'application pour redemander la localisation
    };

    return (
        <div className="d-flex flex-column justify-content-center align-items-center text-white text-center" style={{ height: "100vh" }}>
            <h2>Localisation requise</h2>
            <p>Vous devez activer la localisation pour accéder à l'application.</p>
            <button className="btn btn-primary mb-3" onClick={handleRetry}>
                Réessayer
            </button>
            <p>Pour activer la localisation :</p>
            <ul className="text-start">
                <li>Sur Chrome : Allez dans Paramètres > Confidentialité et sécurité > Paramètres du site > Localisation.</li>
                <li>Sur Firefox : Allez dans Paramètres > Vie privée et sécurité > Autorisations > Localisation.</li>
                <li>Sur Safari : Allez dans Préférences > Sites Web > Localisation.</li>
            </ul>
        </div>
    );
};

export default ErrorPage;
