import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

function LeaveCalendar({ isAdmin }) {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    // Récupère tous les congés avec noms et couleurs pour les admins
    const apiUrl = '/api/getAllLeavesWithColors.php';

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // Formatte les données pour FullCalendar avec couleur unique pour chaque utilisateur
          const formattedEvents = data.leaves.map((leave) => ({
            title: `${leave.user_name} - ${leave.leave_type}`,
            start: leave.start_date,
            end: leave.end_date,
            color: leave.color || 'orange', // Utilise la couleur de l'utilisateur ou une couleur par défaut
          }));
          setEvents(formattedEvents);
        }
      });
  }, [isAdmin]);

  return (
    <div className="container mt-5">
      <h2>Calendrier des Congés</h2>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={events}
        initialDate={new Date()} // Set to today's date
        locale="fr"
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth',
        }}
      />
    </div>
  );
}

export default LeaveCalendar;
