import React, { useState, useEffect } from "react";
import { useJsApiLoader, GoogleMap, DirectionsRenderer } from "@react-google-maps/api";

const Map = ({ origin, destination, step1, step2 }) => {
    const [directionsResponse, setDirectionsResponse] = useState(null);

    const containerStyle = {
        width: '100%',
        height: '300px'
    };

    const center = {
        lat: 48.8566, // Coordonnées par défaut (Paris)
        lng: 2.3522
    };

    // Charger l'API Google Maps avec votre clé et les bibliothèques nécessaires
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API, // Remplacez par votre clé
    });

    const waypoints = [];

    if (step1 !== '' && step1 !== null) {
        waypoints.push({
            location: step1, // Adresse de step1
            stopover: true,
        });
    }

    // Ajout de step2 si valide
    if (step2 !== '' && step2 !== null) {
        waypoints.push({
            location: step2, // Adresse de step2
            stopover: true,
        });
    }


    useEffect(() => {
        if (isLoaded && origin && destination && window.google && window.google.maps) {
            const validOrigin = {
                lat: Number(origin.lat),
                lng: Number(origin.lng),
            };
            const validDestination = {
                lat: Number(destination.lat),
                lng: Number(destination.lng),
            };
            const directionsService = new window.google.maps.DirectionsService();
            directionsService.route(
                {
                    origin: validOrigin, // Utilisez des coordonnées lat/lng valides
                    destination: validDestination,
                    waypoints: waypoints, // Ajout des étapes conditionnelles
                    travelMode: window.google.maps.TravelMode.DRIVING,
                },
                (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        setDirectionsResponse(result);
                    } else {
                        console.error(`Error fetching directions: ${status}`);
                    }
                }
            );
        }
    }, [isLoaded, origin, destination]);

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading...</div>; // Afficher un spinner ou autre contenu pendant le chargement
    }

    return (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
        >
            {directionsResponse && (
                <DirectionsRenderer directions={directionsResponse} />
            )}
        </GoogleMap>
    );
};

export default Map;
