import React, { useState } from "react";
import { useSwipe } from "./SwipeContext"; // Importer le contexte
import "./SwipeableRow.css";

const SwipeableRow = ({
  children,
  BgColor,
  swipeLeftButton,
  swipeRightButton,
  onSwipeLeftAction,
  onSwipeRightAction,
  colorLeftButton,
  colorRightButton,
  userRole,
  isSwipeEnabled,
  isFetchingLocation
}) => {
  const { isSwiping, setIsSwiping } = useSwipe(); // Utiliser l'état global pour le swipe
  const [translateX, setTranslateX] = useState(0);
  const [translateRightX, setTranslateRightX] = useState(0);
  const [translateLeftX, setTranslateLeftX] = useState(0);

  const [startX, setStartX] = useState(0);
  const [swipeDirection, setSwipeDirection] = useState(""); // Nouvel état pour stocker la direction du swipe
  const isSwipeAllowed = (direction) => {
    if ((userRole === "Administrateur" || userRole === 'Super Administrateur') && direction === 'left') return true;
    if (direction === "right" && isSwipeEnabled) return true;
    return false;
  };

  const handleTouchStart = (e) => {
    setIsSwiping(true);
    setStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (!isSwiping) return;
    const deltaX = e.touches[0].clientX - startX;

    if (deltaX < 0 && isSwipeAllowed("left")) {
      setTranslateX(Math.min(0, deltaX));
      setTranslateLeftX(0);
      setSwipeDirection("left"); // Stocker la direction du swipe
    } else if (deltaX > 0 && isSwipeAllowed("right")) {
      setTranslateX(Math.max(0, deltaX));
      setTranslateRightX(0);
      setSwipeDirection("right"); // Stocker la direction du swipe
    }
  };

  const handleTouchEnd = () => {
    finishSwipe();
  };

  const resetSwipe = () => {
    setTranslateX(0);
    setTranslateLeftX(100);
    setTranslateRightX(-100);
    setIsSwiping(false);
  }

  const finishSwipe = () => {
    let swipeRightButtonContent = swipeRightButton;
    let swipeLeftButtonContent = swipeLeftButton();

    // Lorsque le swipe atteint un seuil, les boutons sont considérés comme visibles
    if (swipeLeftButtonContent !== null && translateX < -80) {
      setTranslateX(-150);
      setIsSwiping(true); // Un bouton gauche est visible
    } else if (swipeRightButtonContent !== null && translateX > 80) {
      setTranslateX(150);
      setIsSwiping(true); // Un bouton droit est visible
    } else {
      setTranslateX(0);
      setIsSwiping(false); // Aucun bouton visible
      setSwipeDirection(""); // Réinitialiser la direction
    }
  };

  const leftAction = () => {
    onSwipeLeftAction()
    resetSwipe();
  }


  const rightAction = () => {
    onSwipeRightAction()
    resetSwipe();
  }

  return (
    <div
      className={`swipeable-row shadow-lg p-1 m-1 mb-3 rounded ${BgColor}`}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >

      {swipeLeftButton && (
        <div
          className="swipe-buttons-left"
          onClick={() => leftAction()}
          style={{
            backgroundColor: colorLeftButton !== null ? colorLeftButton : '',
            transform:
              swipeDirection === "left" ? translateLeftX !== 0 ? `translateX(${translateLeftX}%)` : "translateX(0)" : "translateX(100%)"
          }}
        >
          {swipeLeftButton()}
        </div>
      )
      }
      {/* Boutons pour swipe vers la droite */}
      {
        swipeRightButton && (
          <div
            className="swipe-buttons-right"
            disabled={isFetchingLocation}
            onClick={() => rightAction()}
            style={{
              backgroundColor: colorRightButton() !== null ? colorRightButton() : '',
              transform:
                swipeDirection === "right" ? translateRightX !== 0 ? `translateX(${translateRightX}%)` : "translateX(0)" : "translateX(-100%)"
            }}
          >
            {swipeRightButton()}
          </div>
        )
      }
      {/* Contenu principal */}
      <div
        className="swipe-content"
        onClick={() => resetSwipe()}
        style={{
          transform: `translateX(${translateX}px)`,
          transition: "transform 0.5s ease",
        }}
      >
        {children}
      </div>
    </div >
  );
};

export default SwipeableRow;
