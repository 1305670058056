import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
// Components
import "./App.css";
import PrivateRoute from "./PrivateRoute";
import Login from "./components/Login/Login";
import Transfers from "./components/Transfers/Transfers";
import Transfer from "./components/Transfers/Transfer";
import Menu from "./components/Global/Menu";
import BottomMenu from "./components/Global/BottomMenu";
import SignName from "./components/Transfers/SignName";
import Account from "./components/Account/Account";
import { SwipeProvider } from "./components/Transfers/SwipeContext";
import ErrorPage from "./components/Global/ErrorPage"; // Page pour bloquer l'accès en cas de refus
import LeaveManagement from "./components/Leave/LeaveManagement";

const App = () => {
  const [locationPermission, setLocationPermission] = useState(null); // Autorisation de géolocalisation : 'granted' ou 'denied'

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocationPermission("granted");
        },
        (error) => {
          console.error("Erreur de géolocalisation:", error);
          setLocationPermission("denied");
        }
      );
    } else {
      alert("La géolocalisation n'est pas supportée par ce navigateur.");
      setLocationPermission("denied");
    }
  }, []);

  if (locationPermission === "denied") {
    return <ErrorPage />;
  }

  if (locationPermission === "granted") {
    return (
      <Router>
        <Menu />
        <Routes>
          <Route
            path="transfers"
            element={
              <PrivateRoute auth={localStorage.getItem("authenticated")}>
                <SwipeProvider>
                  <Transfers />
                </SwipeProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="transfers/:id"
            element={
              <PrivateRoute>
                <Transfer />
              </PrivateRoute>
            }
          />
          <Route
            path="/sign-name"
            element={
              <PrivateRoute>
                <SignName />
              </PrivateRoute>
            }
          />
          <Route
            path="/account"
            element={
              <PrivateRoute>
                <Account />
              </PrivateRoute>
            }
          />
          <Route
            path="/leaves"
            element={
              <PrivateRoute>
                <LeaveManagement />
              </PrivateRoute>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Navigate replace to="/transfers" />} />
        </Routes>
        <BottomMenu />
      </Router>
    );
  }

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <p>Chargement de la localisation...</p>
    </div>
  );
};

export default App;
